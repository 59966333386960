<template>
  <div class="home">
    <div class="logo">
      <div class="icon animate">
        <span>M</span>
        <span>W</span>
      </div>
      <div class="pipe animate">|</div>
      <div class="name animate">Mike Weaver</div>
      <div class="links">
        <a target="_blank" href="https://www.linkedin.com/in/michael-weaver-8296017b"><i class="fa fa-linkedin-square"></i></a>
        <a target="_blank" href="./Weaver_Michael_Resume.pdf"><i class="fa fa-file-pdf-o"></i></a>
        <a target="_blank" href="./Weaver_Michael_Resume.pages"><i class="fa fa-file-word-o"></i></a>
        <a target="_blank" href="./Weaver_Michael_Resume.txt"><i class="fa fa-file-text-o"></i></a>
        <a target="_blank" href="http://codepen.io/mjweaver01"><i class="fa fa-codepen"></i></a>
        <router-link to="/photography"><i class="fa fa-camera-retro"></i></router-link>
        <router-link to="/contact"><i class="fa fa-envelope"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<style lang="scss" scoped>
@keyframes logo {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes openPipe {
  0%, 50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideName {
  0%, 40% {
    transform: scale(0);
    opacity: 0;
    margin-left: -100%;
  }

  55% {
    opacity: 0;
  }

  100% {
    margin-left: 0%;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideContent {
  0%, 30% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  transform-origin: left;
  transform: scale(1.15) translate(-50%, -50%);
  width: 397px;

  .animate {
    animation: logo 1s;
  }

  .icon {
    display: inline-block;
    will-change: transform;

    span {
      display: inline-block;
      vertical-align: middle;
      font: 400 70px $font-family;

      &:first-of-type {
        margin-right: -15px;
        margin-top: 0px;
      }
    }
  }

  .pipe {
    display: inline-block;
    vertical-align: middle;
    font: 100 70px $font-family;
    margin-top: -10px;
    will-change: transform;

    &.animate {
      animation: openPipe 1s;
    }
  }

  .name {
    display: inline-block;
    vertical-align: middle;
    font: 100 50px $font-family;
    will-change: transform;

    &.animate {
      animation: slideName 2s;
    }
  }

  @media (max-width: 500px) {
    transform: scale(0.8) translate(-50%, -50%);

    .links  {
      font-size: 1.25em;

      a {
        margin: 0.45em !important;
      }
    }
  }

  @media (max-width: 400px) {
    transform: scale(0.7) translate(-50%, -50%);
  }

  .links {
    margin: 0 auto;
    text-align: center;

    a {
      display: inline-block;
      color: $black;
      font-size: 1.5em;
      margin: 0.25em 0.6em;
      transition: color 0.1s;
      cursor: pointer;
      transition: opacity 0.1s;
      animation: slideContent 0.75s;
      animation-fill-mode: backwards;

      @for $i from 1 through 10 {
        &:nth-of-type(#{$i}n) {
          animation-delay: #{$i * 0.25}s;
        }
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
