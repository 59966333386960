import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Client from 'shopify-buy/index.unoptimized.umd'

import photography from './modules/photography'
import work from './modules/work'
import store from './modules/store'
import cart from './modules/cart'
import products from './modules/products'

Vue.use(Vuex)

// ------------------
// VUEX PERSIST
// ------------------
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['photography', 'work', 'store', 'products']
})

// reset when storage is more than X hours
const hours = 1
const now = new Date().getTime()
const setupTime = localStorage.getItem('setupTime')
if (setupTime == null) {
  localStorage.setItem('setupTime', now)
} else {
  if (now - setupTime > hours * 60 * 60 * 1000) {
    window.localStorage.removeItem('vuex')
    window.localStorage.removeItem('cartId')
    localStorage.setItem('setupTime', now)
  }
}

// ------------------
// SHOPIFY BUY SDK CLIENT
// ------------------
const client = Client.buildClient({
  domain: 'mike-weaver.myshopify.com',
  storefrontAccessToken: '75f02a300d4c295dc7f739677ce164c3'
})

if (process.env.NODE_ENV !== 'production') {
  console.log(client) //eslint-disable-line
}

// only add vuex local on production
let localPlugins = []
if (process.env.NODE_ENV === 'production') localPlugins.push(vuexLocal.plugin)

export default new Vuex.Store({
  state: {
    client,
    showSideCart: false
  },

  mutations: {
    toggleSideCart (state) {
      state.showSideCart = !state.showSideCart

      if (state.showSideCart) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  },

  modules: {
    photography,
    work,
    store,
    cart,
    products
  },

  plugins: localPlugins
})
