<template lang="pug">
  .side-cart(v-if="cart && !prerender")
    .overlay(@click="closeSideCart()" v-show="showSideCart")
    .drawer-wrapper(:class="{ 'open': showSideCart }")
      .drawer-header
        h4.cart-text #[a.secondary-font(href="#" @click.prevent="goToCart()") Shopping Cart ({{ cartSize }})]
        .hamburger.open(@click="closeSideCart()") #[.lines]
      cart-component
      subtotal-checkout
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartComponent from './CartComponent'
import SubtotalCheckout from './SubtotalCheckout'

export default {
  components: {
    CartComponent,
    SubtotalCheckout
  },

  computed: {
    ...mapState([
      'showSideCart',
      'prerender'
    ]),

    ...mapState('cart', [
      'cart'
    ]),

    ...mapGetters('cart', [
      'cartSize'
    ])
  },

  methods: {
    closeSideCart () {
      this.$store.commit('toggleSideCart')
    },

    goToCart () {
      const path = '/cart'
      if (this.showSideCart) this.$store.commit('toggleSideCart')
      if (this.$route.path !== path) this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  display: flex;
  flex-flow: column;
  align-items: stretch;

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;
    padding: 1em;

    .cart-text {
      margin: 0;
      flex-grow: 1;
      text-align: center;
      transition: 0.2s all;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .hamburger {
      position: absolute;
      right: 10px;
    }
  }

  .close-cart {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  }

  /deep/ .drawer-header + div {
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    flex-grow: 1;

    .products {
      padding: 1em 1em 0;
    }
  }
}
</style>
