import baseMeta from '@/helpers/meta/baseMeta'
const localBaseMeta = baseMeta()

export default {
  namespaced: true,

  state: {
    storeData: {}
  },

  mutations: {
    setStoreData (state, sentStoreData) {
      state.storeData = sentStoreData
    }
  },

  actions: {
    async getStore (store) {
      const shopQuery = await store.rootState.client.graphQLClient.query((root) => {
        root.add('shop', (store) => {
          store.add('name')
          store.add('description')
        })
      })

      const shopifyStore = await store.rootState.client.graphQLClient.send(shopQuery)
      store.commit('setStoreData', shopifyStore.data.shop)

      window.storeData = {
        title: store.state.storeData.name,
        description: store.state.storeData.description,
        keywords: localBaseMeta.keywords,
        image: localBaseMeta.image
      }
    }
  }
}
