export default function baseMeta () {
  let baseMeta = {
    title: 'Mike Weaver',
    description: 'Mike weaver is a battle-hardened web development expert, with almost a decade of experience in all things UI/UX.',
    keywords: 'mike weaver web developer columbus ohio ui ux front end frontend programmer programming vue js javascript css scss html framework',
    image: 'https://mjweaver01.com/favicon.ico'
  }

  if (window.storeData) baseMeta = { ...baseMeta, ...window.storeData }

  return baseMeta
}
