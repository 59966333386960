import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { setMeta } from '../helpers/meta/setMeta'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/photography',
    name: 'Photography',
    component: () => import(/* webpackChunkName: "about" */ '../views/Photography.vue')
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import(/* webpackChunkName: "about" */ '../views/Work.vue')
  },
  {
    path: '/work/:id',
    name: 'WorkPage',
    reuse: false,
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkPage.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/store',
    name: 'Store',
    component: () => import(/* webpackPrefetch: true */ '../views/Products')
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import(/* webpackPrefetch: true */ '../views/Product')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackPrefetch: true */ '../views/Cart')
  },
  {
    path: '/misc',
    name: 'Misc',
    component: () => import(/* webpackPrefetch: true */ '../views/Misc')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  setMeta(to)
  next()
})

export default router
