export default {
  namespaced: true,

  state: {
    products: [],
    product: {},
    upsellProduct: {},
    upsellHandle: 'frustration-free-packaging'
  },

  mutations: {
    setAllProducts (state, sentProducts) {
      state.products = sentProducts
    },

    setCurrentProduct (state, sentProduct) {
      state.product = sentProduct
    },

    setUpsellProduct (state, sentProduct) {
      state.upsellProduct = sentProduct
    }
  },

  actions: {
    // custom query
    // https://shopify.github.io/js-buy-sdk/#expanding-the-sdk
    // https://community.shopify.com/c/Shopify-APIs-SDKs/GraphQL-get-product-variant-quantity/td-p/483739
    async fetchAllProducts (store) {
      // Build a custom products query using the unoptimized version of the SDK
      const productsQuery = store.rootState.client.graphQLClient.query((root) => {
        root.addConnection('products', { args: { first: 250 } }, (product) => {
          product.add('id')
          product.add('description')
          product.add('descriptionHtml')
          product.add('handle')
          product.add('title')
          product.add('vendor')
          product.add('availableForSale')

          product.addConnection('images', { args: { first: 250 } }, (images) => {
            images.add('url')
            images.add('id')
            images.add('altText')
          })

          product.addConnection('variants', { args: { first: 50 } }, (variant) => {
            variant.add('id')
            variant.add('title')
            variant.add('sku')

            variant.add('price', (opts) => {
              opts.add('amount')
            })

            variant.add('compareAtPrice', (opts) => {
              opts.add('amount')
            })

            variant.add('selectedOptions', (opts) => {
              opts.add('name')
            })
          })
        })
      })

      // Call the send method with the custom products query
      store.rootState.client.graphQLClient.send(productsQuery).then((res) => {
        // transform recieved JSON object to nice form
        // yucky!
        res.data.products.edges.forEach((product, index) => {
          res.data.products.edges[index] = product.node
          res.data.products.edges[index].images = res.data.products.edges[index].images.edges
          res.data.products.edges[index].images.forEach((image, i) => {
            res.data.products.edges[index].images[i] = res.data.products.edges[index].images[i].node
          })
          res.data.products.edges[index].variants = res.data.products.edges[index].variants.edges
          res.data.products.edges[index].variants.forEach((variant, j) => {
            res.data.products.edges[index].variants[j] = res.data.products.edges[index].variants[j].node
          })
        })

        store.commit('setAllProducts', res.data.products.edges)
      })
    },

    async fetchProductByHandle (store, sentHandle) {
      const product = await store.rootState.client.product.fetchByHandle(sentHandle)
      store.commit('setCurrentProduct', product)
    },

    async fetchUpsellProduct (store) {
      const product = await store.rootState.client.product.fetchByHandle(store.state.upsellHandle)
      store.commit('setUpsellProduct', product)
    }
  },

  getters: {
    currentProductIndex (state) {
      let currentIndex = 0

      state.products.forEach((product, index) => {
        if (product.id === state.product.id) {
          currentIndex = index
        }
      })

      return currentIndex
    },

    previousProduct (state, getters) {
      return state.products[getters.currentProductIndex - 1]
    },

    nextProduct (state, getters) {
      return state.products[getters.currentProductIndex + 1]
    },

    orderedProducts (state) {
      let prods = state.products || []
      return prods.sort((a, b) => {
        return b.availableForSale - a.availableForSale
      })
    },

    relatedProducts (state, getters) {
      const n = 4
      let randomProducts = []

      for (let i = 0; i < state.products.length; i++) {
        const formattedProduct = getters.orderedProducts[i]

        if (
          formattedProduct.title !== state.product.title &&
          formattedProduct.handle !== state.upsellHandle
        ) {
          randomProducts.push(formattedProduct)
        }
      }

      return randomProducts
        .map(x => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map(a => a.x)
        .slice(0, n)
    }
  }
}
