<template lang='pug'>
  .cart-icon(@click="openCart()")
    span.emoji 🛒&nbsp;
    span {{ cartSize }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CartIcon',

  computed: {
    ...mapState('cart', [
      'cartId',
      'cart'
    ]),

    ...mapGetters('cart', [
      'cartSize'
    ])
  },

  beforeMount () {
    // see if we have cartId stored (Lives outside of Vuex Persist)
    const cartId = localStorage.getItem('cartId')

    if (cartId) {
      this.$store.commit('cart/setCartId', cartId)
      this.$store.dispatch('cart/fetchCart')
    }

    this.$nextTick(() => {
      if (this.cartId !== '') {
        this.$store.dispatch('cart/fetchCart')
      } else {
        this.$store.dispatch('cart/createCart')
      }
    })
  },

  methods: {
    openCart () {
      if (!this.$router.history.current.path.includes('cart')) {
        this.$store.commit('toggleSideCart')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-icon {
  padding-right: 0.5em;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;

  .emoji {
    font-size: 1.25em;
    display: inline-block;
  }
}
</style>
