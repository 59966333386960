var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"logo"},[_vm._m(0),_c('div',{staticClass:"pipe animate"},[_vm._v("|")]),_c('div',{staticClass:"name animate"},[_vm._v("Mike Weaver")]),_c('div',{staticClass:"links"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{attrs:{"to":"/photography"}},[_c('i',{staticClass:"fa fa-camera-retro"})]),_c('router-link',{attrs:{"to":"/contact"}},[_c('i',{staticClass:"fa fa-envelope"})])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon animate"},[_c('span',[_vm._v("M")]),_c('span',[_vm._v("W")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.linkedin.com/in/michael-weaver-8296017b"}},[_c('i',{staticClass:"fa fa-linkedin-square"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"./Weaver_Michael_Resume.pdf"}},[_c('i',{staticClass:"fa fa-file-pdf-o"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"./Weaver_Michael_Resume.pages"}},[_c('i',{staticClass:"fa fa-file-word-o"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"./Weaver_Michael_Resume.txt"}},[_c('i',{staticClass:"fa fa-file-text-o"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"http://codepen.io/mjweaver01"}},[_c('i',{staticClass:"fa fa-codepen"})])
}]

export { render, staticRenderFns }