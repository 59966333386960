export default {
  namespaced: true,

  state: {
    work: [
      {
        id: 'triple-whale',
        name: 'Triple Whale',
        showName: false,
        url: 'https://triplewhale.com/',
        logo: './img/logos/triplewhale-logo.png',
        logoWidth: 250,
        logoHeight: 56,
        descriptions: [
          `<img src="/img/logos/whatistw.png" class="full-width" alt="According to Triple Whale's AI, Triple Whale is a cutting-edge analytics platform designed specifically for e-commerce businesses. It centralizes data from various marketing channels, e-commerce platforms, and customer interactions to provide comprehensive insights into sales, marketing performance, and customer behavior. By leveraging Triple Whale, businesses can optimize their digital marketing strategies, improve online store performance, and enhance customer experiences, ultimately driving growth and increasing efficiency in their operations." />` +
          'Working out of <strong>Triple Whale</strong>\'s US-based office in Columbus, I report directly to the Co-founder & CTO <a href="https://il.linkedin.com/in/avrahamorbach" target="_blank">AJ Orbach</a>, however I work with peers from all over the world and all walks of life. ',
          'I function as an individual contributor across Triple Whale\'s suite of tools, collaborating mainly with IL-based full-stack developers and US-based PMs. ' +
          'During my tenure I have been part of several teams:' +
          `
            <br/>
            <ul>
              <li>The “Commando” Hit Squad (Architecture)</li>
              <li>Core App Team (React/Firebase)</li>
              <li>Dataland (BigQuery)</li>
              <li>Integrations (Temporal)</li>
              <li>AI/BI (LLM/ARIMA)</li>
            </ul>
            <br/>
          ` +
          'Working with some of the best developers in the world, I have been directly involved with several projects and initiatives in the company:' +
          `
          <br/>
          <ul>
            <li>AI Chat, NLQ Widgets, and Dashboards (GPT/Prompt Engineering/Firebase)</li>
            <li>Full Stack Build Processes (TypeScript/Vite)</li>
            <li>Session Replay Sales Tool (Vite/Chrome Extension)</li>
            <li>External API (Ory Hydra)</li>
            <li>Error Boundary (React)</li>
            <li>Event Tracking (PostHog)</li>
            <li>System Health Monitoring (DataDog)</li>
            <li>On-Call & Procedure (OpsGenie)</li>
            <li>Technical Documentation (Confluence)</li>
            <li>Agile SDLC (JIRA/SCRUM)</li>
          </ul>
          <br/>
          `
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'TypeScript',
            link: 'https://www.typescript.com/'
          },
          {
            name: 'Node',
            link: 'https://nodejs.org/en/'
          },
          {
            name: 'Express',
            link: 'https://expressjs.com/'
          },
          {
            name: 'React',
            link: 'https://reactjs.org/'
          },
          {
            name: 'Redux',
            link: 'https://redux.js.org/'
          },
          {
            name: 'Polaris',
            link: 'https://polaris.shopify.com/'
          },
          {
            name: 'Mantine',
            link: 'https://mantine.dev/'
          },
          {
            name: 'Tailwind',
            link: 'https://tailwindcss.com/'
          },
          {
            name: 'Firebase',
            link: 'https://firebase.google.com/'
          },
          {
            name: 'Vite',
            link: 'https://vitejs.dev/'
          },
          {
            name: 'ChatGPT',
            link: ' https://chat.openai.com/auth/login'
          }
        ]
      },
      {
        id: 'crooked-youth',
        name: 'Crooked Youth',
        showName: false,
        url: 'https://crookedyouth.co/',
        logo: './img/logos/cy-logo.png',
        logoWidth: 250,
        logoHeight: 56,
        descriptions: [
          '<strong>Crooked Youth</strong> builds design forward Shopify sites and strategic marketing automations for the entertainment industry.',
          'Working directly with the founders <a href="https://www.linkedin.com/in/alex-lira-1338bba1" target="_blank">Alex Lira</a> and <a href="https://www.linkedin.com/in/stephmirsky/" target="_blank">Steph Mirsky</a>, I consult as an individual contributor, providing advanced features and functionality for their custom site builds.',
          'High profile artists trust Crooked Youth with their ecommerce sites, and they trust me with providing high quality development to serve their needs.',
          `Some of the sites I've serviced include:
          <br>
          <ul>
          <li><a href="https://dancegavindanceband.com/" target="_blank">Dance Gavin Dance</a></li>
          <li><a href="https://www.deathrowofficial.com/" target="_blank">Death Row Records</a></li>
          <li><a href="https://goodfightmusic.com/" target="_blank">Good Fight Music</a></li>
          <li><a href="https://store.hollywoodundead.com/" target="_blank">Hollywood Undead</a></li>
          <li><a href="https://paparoachmerch.com/" target="_blank">Papa Roach</a></li>
          <li><a href="https://riseagainst.store/" target="_blank">Rise Against</a></li>
          <li><a href="https://riserecords.com/" target="_blank">Rise Records</a></li>
          <li><a href="https://singlemusic.com/" target="_blank">Single Music</a></li>
          </ul>`
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Shopify',
            link: 'https://www.shopify.com/'
          }
        ]
      },
      {
        id: 'electric-eye',
        name: 'Electric Eye',
        showName: false,
        url: 'https://electriceye.io/',
        logo: './img/logos/ee-logo.svg',
        logoWidth: 240,
        logoHeight: 40,
        descriptions: [
          '<strong>Electric Eye</strong> is a boutique agency focused on helping brands grow their online sales. We are certified Shopify Experts who provide well-designed, data-driven solutions, allowing our clients to worry less about technology, and focus on running their business.',
          'At Electric Eye, I was the technical lead and sole on-staff developer, responsible for delivering all programming related services offered by the agency. I report directly to the owners, <a href="http://www.chaseclymer.com/" target="_blank">Chase Clymer</a> and <a href="https://khemsurov.com/" target="_blank">Shawn Khemsurov</a>.',
          'My general responsibilities as a lead were: investigating and implementing new and existing technologies, dictating technical standards, and transferring knowledge via documentation and standard operating procedures.',
          'I\'ve touched dozens of sites during my tenure at Electric Eye, working on projects both small and large. Broadly, those projects entail: custom theme builds, premium theme setups, custom feature development, custom applications, app setups, SEO/Lighthouse optimizations, ADA compliance, and code cleanups.',
          `Some of the sites I've serviced include:
          <br>
          <ul class="split">
          <li><a href="https://arcticcool.com/" target="_blank">Arctic Cool</a></li>
          <li><a href="https://arnoldparkstudios.com/" target="_blank">Arnold Park Studios</a> <strong>*</strong></li>
          <li><a href="https://ballwash.com/" target="_blank">Ballsy</a></li>
          <li><a href="https://basicbrandsinc.com/" target="_blank">Basic Brands</a> <strong>*</strong></li>
          <li><a href="https://beartoothmerch.com/" target="_blank">Beartooth</a> <strong>*</strong></li>
          <li><a href="https://www.betsyboosboutique.com/" target="_blank">Betsy Boo's Boutique</a></li>
          <li><a href="https://broccolimag.com/" target="_blank">Broccoli Magazine</a> <strong>*</strong></li>
          <li><a href="https://camphigh.com/" target="_blank">Camp High</a></li>
          <li><a href="https://corporategotem.com/" target="_blank">Corporate Gotem</a></li>
          <li><a href="http://darbycreektrading.com/" target="_blank">Darby Creek Trading</a></li>
          <li><a href="https://dermspastore.com/" target="_blank">Derm Spa Store</a> <strong>*</strong></li>
          <li><a href="https://shop.design-milk.com/" target="_blank">Design Milk</a></li>
          <li><a href="https://dripaccessory.com/" target="_blank">Drip Accessory</a></li>
          <li><a href="https://www.electriceye.io/" target="_blank">Electric Eye (Agency's Site)</a> <strong>*</strong></li>
          <li><a href="https://embassyboardshop.com/" target="_blank">Embassy Boardshop</a> <strong>*</strong></li>
          <li><a href="https://goldensteersteakcompany.com/" target="_blank">Golden Steer Steak</a></li>
          <li><a href="https://shop.hellohuman.us/" target="_blank">Hello Human</a> <strong>*</strong></li>
          <li><a href="https://honestecommerce.co/" target="_blank">Honest Ecommerce</a></li>
          <li><a href="https://kidmademodern.com/" target="_blank">Kid Made Modern</a>
          <li><a href="https://liliasactive.com/" target="_blank">Lilias Active</a></li>
          <li><a href="https://livate.co/" target="_blank">Livate</a></li>
          <li><a href="https://medspa-mastery.com/" target="_blank">Medspa Mastery</a></li>
          <li><a href="https://www.offthewagonshop.com/" target="_blank">Off The Wagon</a></li>
          <li><a href="https://www.ohiomulch.com/" target="_blank">Ohio Mulch</a></li>
          <li><a href="https://onlyny.com/" target="_blank">Only NY</a></li>
          <li><a href="https://pebbyforevee.com/" target="_blank">Pebby Forevee</a></li>
          <li><a href="https://liveplusultra.com/" target="_blank">Plus Ultra</a></li>
          <li><a href="https://poolandspasale.com/" target="_blank">Pool and Spa Sale</a></li>
          <li><a href="https://ranchogordo.com" target="_blank">Rancho Gordo</a></li>
          <li><a href="https://simpleanddainty.com/" target="_blank">Simple & Dainty</a> <strong>*</strong></li>
          <li><a href="https://tiptopshoes.com/" target="_blank">Tip Top Shoes</a></li>
          <li><a href="https://www.shop-tetra.com/" target="_blank">Tetra</a> <strong>*</strong></li>
          <li><a href="https://vg.plus/" target="_blank">Very Good Plus</a> <strong>*</strong></li>
          <li><a href="https://vktrygear.com/" target="_blank">VKTRY</a></li>
          <li><a href="https://kit.warroad.com/" target="_blank">Warroad (Kit Builder)</a> <strong>*</strong></li>
          <li><a href="https://westside-barbell.com/" target="_blank">Westside Barbell</a> <strong>*</strong></li>
          <li><a href="https://loveyubi.com/" target="_blank">Yubi Beauty</a></li>
          </ul>
          <p><strong>* Indicates a fully custom build</strong>
          `
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Shopify',
            link: 'https://www.shopify.com/'
          },
          {
            name: 'Prismic',
            link: 'https://prismic.io/'
          },
          {
            name: 'Vue',
            link: 'https://vuejs.org/'
          },
          {
            name: 'Vuex',
            link: 'https://vuex.vuejs.org/'
          },
          {
            name: 'Vue CLI',
            link: 'https://cli.vuejs.org/'
          },
          {
            name: 'Vite',
            link: 'https://vitejs.dev/'
          },
          {
            name: 'Astro',
            link: 'https://astro.build/'
          },
          {
            name: 'VuePress',
            link: 'https://vuepress.vuejs.org/'
          },
          {
            name: 'Netlify CMS',
            link: 'https://www.netlifycms.org/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'Pug',
            link: 'https://pugjs.org/'
          },
          {
            name: 'jQuery',
            link: 'https://jquery.com/'
          },
          {
            name: 'Netlify',
            link: 'https://www.netlify.com/'
          },
          {
            name: 'Webpack',
            link: 'https://webpack.js.org/'
          },
          {
            name: 'Node',
            link: 'https://nodejs.org/en/'
          },
          {
            name: 'React',
            link: 'https://reactjs.org/'
          },
          {
            name: 'Next',
            link: 'https://nextjs.org/'
          },
          {
            name: 'Polaris',
            link: 'https://polaris.shopify.com/'
          },
          {
            name: 'Figma',
            link: 'https://figma.com/'
          }
        ]
      },
      {
        id: 'rogue',
        name: 'Rogue Fitness',
        showName: false,
        url: 'https://www.roguefitness.com/',
        logo: './img/logos/rogue-logo.svg',
        logoWidth: 275,
        logoHeight: 43,
        descriptions: [
          '<strong>Rogue Fitness</strong> is the leading manufacturer in strength and conditioning equipment & an official sponsor of the CrossFit Games, Arnold Classic, and USA Weightlifting. From power racks, rigs, and barbells to shoes, apparel & accessories, Rogue\'s online store equips garage gyms, military, pros & more.',
          'Rogue hired me on as a Frontend Developer 1, but during my 5 year tenure I rose the ranks through Frontend Developer 2, Senior Frontend Developer, and lastly Lead Frontend Developer.',
          'Over the years, I touched and eventually owned all the code behind Rogue\'s user experience, working directly under the UX manager, <a href="https://www.linkedin.com/in/michaeljpaulus/" target="_blank">Michael Paulus</a>. I\'ve done everything from email templates, to A/B testing, to general frontend features and maintenance, to architecting and developing full featured sites; the first of which was <a href="https://roguefitness.com/theindex" target="_blank">The Index</a>, a site dedicated to all non-revenue-generating content.',
          'As a lead, I owned the frontend of Rogue’s 6 e-commerce sites and The Index. I was chief architect for all frontend features, investigated new technologies, dictated technical standards, and transferred that knowledge to peers. I also lead pods, planned sprints, and delegated tasks within a fast paced SCRUM environment.',
          'Before resigning, I architected the frontend for Rogue\'s initiative to migrate from Magento 1, to a headless Single Page App on Magento 2. I formulated the technical standards, design system, and app architecture still in use today.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Magento 1 & 2',
            link: 'https://magento.com/'
          },
          {
            name: 'WordPress',
            link: 'https://wordpress.org/'
          },
          {
            name: 'Vue',
            link: 'https://vuejs.org/'
          },
          {
            name: 'Vuex',
            link: 'https://vuex.vuejs.org/'
          },
          {
            name: 'Vue CLI',
            link: 'https://cli.vuejs.org/'
          },
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'Pug',
            link: 'https://pugjs.org/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'PHP',
            link: 'https://www.php.net/'
          },
          {
            name: 'Firebase',
            link: 'https://firebase.google.com/'
          },
          {
            name: 'Gulp',
            link: 'https://gulpjs.com/'
          },
          {
            name: 'Webpack',
            link: 'https://webpack.js.org/'
          },
          {
            name: 'InVision',
            link: 'https://www.invisionapp.com/'
          },
          {
            name: 'Sketch',
            link: 'https://www.sketch.com/'
          }
        ]
      },
      {
        id: 'westinghouse',
        name: 'Westinghouse Outdoor Power',
        showName: false,
        url: 'https://westinghouseoutdoorpower.com/',
        logo: './img/logos/westinghouse-logo.png',
        logoWidth: 900,
        logoHeight: 193,
        descriptions: [
          '<strong>Westinghouse</strong> is one of the leading manufacturers in outdoor power equipment. Groundbreaking technology and rock-solid dependability have made them one of the world’s most trusted brands.',
          'Working under the eCommerce manager and friend <a href="https://www.linkedin.com/in/brian-altier/" target="_blank">Brian Altier</a>, Westinghouse (under MWE) hired me as their sole web developer, in charge of migrating their old Magento site to Shopify, starting from scratch.',
          'During the migration, I developed a custom Shopify app to make it easier for data analysts to create and maintain new products. Dynamically generating a template complete with all the fields necessary to create a product, I reduced product creation time down from hours, to minutes.',
          'The new site\'s was built from the ground up, and so was the theme. I created a custom build that is abnormal to typical Shopify development, but creates a lightweight website without any of the bloat generally associated with other "premium" themes.',
          'After the new theme was finished, I created a documentation website to accompany it, so that anyone in the company could understand, and even (theoretically) develop it.',
          'Subsequently, I developed a custom Node app to dynamically pull data from a spreadsheet, and transform it into geolocation data stored within Firebase. This app allowed data analysts to run this data sync at any time, eliminating the manual process that preceded it. The data generated is used on the frontend in the form of a map, displaying Westinghouse\'s various service centers in the US and Canada.',
          'I continue to maintain and create new features for the site on an ad-hoc basis.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Shopify',
            link: 'https://www.shopify.com/'
          },
          {
            name: 'Vue',
            link: 'https://vuejs.org/'
          },
          {
            name: 'Vuex',
            link: 'https://vuex.vuejs.org/'
          },
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'Firebase',
            link: 'https://firebase.google.com/'
          },
          {
            name: 'Node',
            link: 'https://nodejs.org/en/'
          },
          {
            name: 'Express',
            link: 'https://expressjs.com/'
          },
          {
            name: 'React',
            link: 'https://reactjs.org/'
          },
          {
            name: 'Redux',
            link: 'https://redux.js.org/'
          },
          {
            name: 'Polaris',
            link: 'https://polaris.shopify.com/'
          },
          {
            name: 'Netlify',
            link: 'https://www.netlify.com/'
          },
          {
            name: 'Webpack',
            link: 'https://webpack.js.org/'
          },
          {
            name: 'InVision',
            link: 'https://www.invisionapp.com/'
          }
        ]
      },
      {
        id: 'triple-crown',
        name: 'Triple Crown',
        showName: true,
        url: 'https://triplecrownpoetry.com/',
        logo: './img/logos/triple-crown-logo.png',
        logoWidth: 807,
        logoHeight: 745,
        descriptions: [
          '<strong><i>Triple Crown</i></strong> is the only triple heroic crown of sonnets in the English language. The highly structured and interwoven poems deal with three aspects of reality as seen through the lens of scripture: the universal, the societal, and the personal.',
          'Triple Crown was written by <a href="https://www.linkedin.com/in/eric-chevlen-9201ba6/" target="_blank">Dr. Eric Chevlen</a>, a devout student of Holy Scripture, medical oncologist, and father of two sets of fraternal twins (who also happen to be four of my best friends). Dr. Chevlen has always been someone I both admired and respected. In an attempt to repay him for being such a wonderful role model and father, I developed this website to help him increase exposure to his book.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Vue',
            link: 'https://vuejs.org/'
          },
          {
            name: 'VuePress',
            link: 'https://vuepress.vuejs.org/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'Netlify',
            link: 'https://www.netlify.com/'
          }
        ]
      },
      {
        id: 'mask-makers',
        name: 'Mask Makers',
        showName: false,
        url: 'https://beamaskmaker.org/',
        logo: './img/logos/mask-makers-logo.svg',
        logoWidth: 300,
        logoHeight: 104,
        descriptions: [
          '<strong>Mask Makers</strong> is a grassroots effort to help protect healthcare workers on the frontlines of the COVID-19 pandemic. Their volunteer-driven mission is to connect ordinary citizens who were sheltering in place (with needle and thread!) to the doctors, nurses and hospital staff who need more masks.',
          'Mask Makers is the brain child of <a href="http://michaelmarucco.net/about/" target="_blank">Michael Marucco</a>, who recruited industry experts from all over the world to assist in the effort. Among those were employees from big brand names like: Apple, Bacardi, Hollister, Abercrombie & Fitch, and many other highly-skilled independent contractors. I was honored to be counted among the ranks of this star-studded group.',
          'Working alongside a former colleague and friend <a href="http://drewhaas.com/" target="_blank">Drew Haas</a>, we developed this site using all our favorite technologies to produce a beautiful and blazing fast Single Page App.',
          'Drew and I handled everything involving the site: development, hosting, SEO, and Analytics. We also created a staging environment and trained data analysts on how to enter, test, and deploy new data to the site.',
          'During the COVID-19 pandemic, Drew and I along with all the others involved donated our time and expertise to the cause.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Vue',
            link: 'https://vuejs.org/'
          },
          {
            name: 'Vuex',
            link: 'https://vuex.vuejs.org/'
          },
          {
            name: 'Vue CLI',
            link: 'https://cli.vuejs.org/'
          },
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'Pug',
            link: 'https://pugjs.org/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'Firebase',
            link: 'https://firebase.google.com/'
          },
          {
            name: 'Vercel',
            link: 'https://vercel.com/'
          }
        ]
      },
      {
        id: 'strides',
        name: 'Strides App',
        showName: false,
        url: 'https://www.stridesapp.com/',
        logo: './img/logos/strides-logo.png',
        logoWidth: 480,
        logoHeight: 100,
        descriptions: [
          '<strong>Strides</strong> is a mobile app that allows you to track anything - good or bad habits - with reminders to hold you accountable and charts to keep you motivated. Recommended in The New York Times, Strides has been called "the most comprehensive, user-friendly, beautifully designed goal tracking app available."',
          'Strides hired me (via a colleague, and co-founder <a href="https://www.linkedin.com/in/jasongrim/" target="_blank">Jason Grim</a>) to take over all the work from the previous frontend developer. For several months I worked with them maintaining and improving their Single Page App, which became the new codebase for the mobile app.',
          'During my time with Strides, I helped with various UX updates, feature releases, and overall code optimization and restructuring.'

        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'Angular.js',
            link: 'https://angularjs.org/'
          },
          {
            name: 'Laravel',
            link: 'https://laravel.com/'
          },
          {
            name: 'PHP',
            link: 'https://www.php.net/'
          },
          {
            name: 'Webpack',
            link: 'https://webpack.js.org/'
          }
        ]
      },
      {
        id: 'go-get-hires',
        name: 'Go Get Hires',
        showName: false,
        url: 'http://gogethires.com/',
        logo: './img/logos/ggh-logo.png',
        logoWidth: 61,
        logoHeight: 76,
        descriptions: [
          '<strong>Go Get Hires</strong> was a remote IT staffing firm, providing remote, full-time hires that costed half as much as local IT specialists.',
          'Founded by colleague <a href="https://www.linkedin.com/in/vladimir-klimau/" target="_blank">Vladimir Kilmov</a>, I was the first employee of the agency. During my time there, I developed and maintained the company\'s website, and created all the frontend code tests for vetting new potential staff members.'
        ],
        technologiesTitle: 'Technology',
        technologies: [
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'ES6',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'Gulp',
            link: 'https://gulpjs.com/'
          }
        ]
      },
      {
        id: 'dancor',
        name: 'Dancor Solutions',
        showName: false,
        url: 'https://dancorsolutions.com/',
        logo: './img/logos/dancor-logo.png',
        logoWidth: 500,
        logoHeight: 128,
        descriptions: [
          '<strong>Dancor Solutions</strong> is a communications strategy company that provides best in class solutions through Strategy, Design, Technology and Print leveraging their Innovative, Customized, End2End Solutions.',
          'Dancor hired me straight out of college, and was integral in the move from my hometown of Youngstown, to Columbus; providing a springboard for my budding career here.',
          'During my employment at Dancor, I split time between IT and development, working on everything from setting up servers, workstations, routers, and switches, as well as developing websites and skinning customer portals for clients.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'WordPress',
            link: 'https://wordpress.org/'
          },
          {
            name: 'ES5',
            link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
          },
          {
            name: 'jQuery',
            link: 'https://jquery.com/'
          },
          {
            name: 'PHP',
            link: 'https://www.php.net/'
          }
        ]
      },
      {
        id: 'brenner',
        name: 'Brenner Industrial Sales & Supply',
        showName: false,
        url: 'http://brennersales.com/',
        logo: './img/logos/brenner-logo.png',
        logoWidth: 647,
        logoHeight: 178,
        descriptions: [
          '<strong>Brenner Industrial Sales & Supply</strong> is the leading industrial distribution company in northeast Ohio. Brenner has been serving the Mahoning Valley and surrounding areas for over 86 years, and with their years of experience and knowledgeable staff, they strive daily to meet the ever-growing needs of their customers.',
          'Initially hired as a paid intern, Brenner eventually brought me on as a part-time employee. I took Brenner into the 21st century; upgrading switches, routers, and workstations, as well as developing and maintaining the web presence for the company.',
          'Being sort of a \'Jack of All Trades\', Brenner allowed me to assist their Lead Engineer in building and developing electrical panels, and even going out on sales calls with the owner as a technical consultant.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'WordPress',
            link: 'https://wordpress.org/'
          },
          {
            name: 'PHP',
            link: 'https://www.php.net/'
          },
          {
            name: 'jQuery',
            link: 'https://jquery.com/'
          }
        ]
      },
      {
        id: 'mickeys-army-navy',
        name: 'Mickey\'s Army-Navy',
        showName: false,
        url: 'https://army-navy.com/',
        logo: './img/logos/mickeys-logo.png',
        logoWidth: 435,
        logoHeight: 174,
        descriptions: [
          '<strong>Mickey’s Army-Navy</strong> was a surplus army-navy store in Warren, Ohio. Established by U.S. Army veteran Marshall “Mickey” Cohen in 1948, Mickey’s was dedicated to serving working people by offering them top-quality work wear, sporting goods and army-navy surplus at reasonable prices.',
          'At Mickey\'s, I worked part time as an IT professional and webmaster, setting up routers and workstations, as well as maintaining the company\'s online e-commerce store; reporting directly to the owner, <a href="https://www.linkedin.com/in/martin-cohen-518125b6/" target="_blank">Marty Cohen</a>.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'SunShop',
            link: 'https://www.sunshop.com/'
          },
          {
            name: 'PHP',
            link: 'https://www.php.net/'
          },
          {
            name: 'jQuery',
            link: 'https://jquery.com/'
          }
        ]
      },
      {
        id: 'homeowners-handyman',
        name: 'Homeowner\'s Handyman',
        showName: true,
        url: 'https://homeownershandyman.info/',
        logo: './img/logos/hh-logo.png',
        logoWidth: 499,
        logoHeight: 131,
        descriptions: [
          '<strong>Homeowner\'s Handyman</strong> is a one-man handyman band, offering general all around handyman and home repairs to the northeast Ohio area.</strong>',
          'Owned and operated by my Dad, I took the initiative to create his business\'s online presence, allowing it to be searchable, and to showcase the services he offers online; subsequently doubling his exposure and potential workload.',
          'He now touts a 5 star rating on Google even after several years of business, due to his impeccable, honest, and hard work.'
        ],
        technologiesTitle: 'Platforms & Technologies',
        technologies: [
          {
            name: 'SCSS',
            link: 'https://sass-lang.com/'
          },
          {
            name: 'jQuery',
            link: 'https://jquery.com/'
          },
          {
            name: 'Gulp',
            link: 'https://gulpjs.com/'
          }
        ]
      }
    ]
  }
}
