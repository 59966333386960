export default {
  namespaced: true,

  state: {
    cartId: '',
    cart: {},
    addingToCart: false,
    updatingCart: false,
    emptyingCart: false
  },

  mutations: {
    setCartId (state, sentId) {
      state.cartId = sentId
    },

    setCart (state, sentCart) {
      state.cart = sentCart
    },

    toggleAddingToCart (state) {
      state.addingToCart = !state.addingToCart
    },

    toggleUpdatingCart (state) {
      state.updatingCart = !state.updatingCart
    },

    toggleEmptyingCart (state) {
      state.emptyingCart = !state.emptyingCart
    }
  },

  actions: {
    async createCart (store) {
      const cart = await store.rootState.client.checkout.create()

      localStorage.setItem('cartId', cart.id)
      store.commit('setCartId', cart.id)
      store.commit('setCart', cart)
    },

    async fetchCart (store) {
      await store.rootState.client.checkout.fetch(store.state.cartId).then((cart) => {
        store.commit('setCart', cart)
      }).catch(() => {
        store.dispatch('createCart')
      })
    },

    async addProduct (store, sentProductData) {
      store.commit('toggleAddingToCart')
      const cart = await store.rootState.client.checkout.addLineItems(store.state.cartId, sentProductData)
      store.commit('setCart', cart)
      store.commit('toggleAddingToCart')
    },

    async updateProduct (store, sentProductData) {
      store.commit('toggleUpdatingCart')
      const cart = await store.rootState.client.checkout.updateLineItems(store.state.cartId, sentProductData)
      store.commit('setCart', cart)
      store.commit('toggleUpdatingCart')
    },

    async removeProduct (store, sentProductData) {
      store.commit('toggleUpdatingCart')
      const cart = await store.rootState.client.checkout.removeLineItems(store.state.cartId, sentProductData)
      store.commit('setCart', cart)
      store.commit('toggleUpdatingCart')
    },

    async emptyCart (store) {
      store.commit('toggleEmptyingCart')
      store.commit('toggleUpdatingCart')
      let lineItemIdsToRemove = []
      store.state.cart.lineItems.forEach(item => lineItemIdsToRemove.push(item.id))

      const cart = await store.rootState.client.checkout.removeLineItems(store.state.cartId, lineItemIdsToRemove)
      store.commit('setCart', cart)
      store.commit('toggleEmptyingCart')
      store.commit('toggleUpdatingCart')
    }
  },

  getters: {
    cartSize (state) {
      let cartAmount = 0
      if (state.cart && state.cart.lineItems) {
        state.cart.lineItems.forEach(item => { cartAmount += item.quantity })
      }

      return cartAmount
    }
  }
}
