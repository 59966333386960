<template lang="pug">
div
  loading(v-if='!cart.lineItems' page="cart")
  .cart-content(v-else)
    .products
      .product(
        v-for='(item, index) in cart.lineItems' :key='item.id'
      )
        .product-image(@click="goToProductFromCart(item, true)")
          vue-image.product-image(
            :source='item.variant.image && item.variant.image.src || item.images.length > 0 && item.images[0].src || ""'
            :height='500'
            :width='500'
          )
        .product-info
          h4.title(@click="goToProductFromCart(item, true)") {{ item.title }}
          p.variant(v-if='item.variant.title !== "Default Title"') {{ item.variant.title }}
          p.remove
            a(
              href='#'
              @click.prevent="removeProduct(item.id)"
              :class="{ 'removing': productRemovingId === item.id }"
            ) {{ productRemovingId === item.id ? 'Removing' : 'Remove' }}
        .product-quantity
          .product-total(:class="{ 'has-discount': item.discountAllocations.length > 0 || parseFloat(item.variant.compareAtPrice?.amount) > parseFloat(item.variant.price.amount) }")
            p.at-price(v-if="item.quantity > 1") {{ item.quantity }} @ {{ (item.variant.price?.amount) | currency }}
            p.price(:class="{ 'original-price': item.discountAllocations.length > 0 || parseFloat(item.variant.compareAtPrice?.amount) > parseFloat(item.variant.price.amount) }")
              strong {{ ((parseFloat(item.variant.compareAtPrice?.amount) > parseFloat(item.variant.price.amount) ? item.variant.compareAtPrice.amount : item.variant.price?.amount) * item.quantity) | currency }}
            p.price(v-if="item.discountAllocations.length > 0 || parseFloat(item.variant.compareAtPrice?.amount) > parseFloat(item.variant.price.amount)")
              strong {{ (parseInt(item.variant.price?.amount) * item.quantity) - (item.discountAllocations.length > 0 && item.discountAllocations[0].allocatedAmount.amount || 0) | currency }}
          quantity-input(
            :value="item.quantity"
            v-bind:is-cart="true"
            v-bind:loading="productUpdatingId === item.id"
            @input='updateQuantity($event, item.id)'
          )
      upsell
    .cart-is-empty(v-if="cart.lineItems.length === 0")
      h3 Nothing in your cart
      button(@click="viewProducts()") View Store
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { goToProduct, goToProductFromCart } from '@/helpers/goToProduct'
import upsell from './upsell'

export default {
  name: 'CartComponent',

  components: {
    upsell
  },

  data: () => ({
    timeout: 0,
    productUpdatingId: -1,
    productRemovingId: -1
  }),

  computed: {
    ...mapState('cart', [
      'cart'
    ]),

    ...mapGetters('cart', [
      'cartSize'
    ]),

    ...mapState('products', [
      'product',
      'products'
    ]),

    ...mapState([
      'showSideCart'
    ])
  },

  methods: {
    goToProduct,
    goToProductFromCart,

    async updateQuantity (event, sentId) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        this.productUpdatingId = sentId

        await this.$store.dispatch('cart/updateProduct', {
          quantity: event,
          id: sentId
        })

        this.productUpdatingId = -1
      }, 250)
    },

    async removeProduct (sentId) {
      this.productRemovingId = sentId
      await this.$store.dispatch('cart/removeProduct', sentId)
      this.productRemovingId = -1
    },

    viewProducts () {
      const path = '/store'
      if (this.$route.path !== path) this.$router.push(path)
      if (this.showSideCart) this.$store.commit('toggleSideCart')
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0;

  .product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $gray;
    margin-bottom: 1em;
    padding-bottom: 1em;
    text-align: left;

    .product-image {
      flex-basis: 25%;
      cursor: pointer;
    }

    .product-info {
      flex-basis: 37%;
      flex-grow: 1;
      padding: 0 1em;

      .title {
        font-weight: bold;
        margin: 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .variant {
        margin: 0;
        font-size: 0.85em;
      }

      .remove {
        font-size: 0.8em;
        margin: 1em 0 0;
        text-transform: capitalize;
        line-height: 1;

        a {
          padding: 0 !important;
        }
      }
    }

    .product-quantity {
      display: flex;
      flex-flow: column-reverse;
      align-items: flex-end;
      flex-basis: 20%;
      height: 100%;
      justify-content: space-between;

      input {
        max-width: 50px;
        margin: 0 auto;
      }

      p {
        white-space: nowrap;
        margin: 0 auto;
        text-align: center;
        font-size: 1.25em;
      }
    }

    .product-total {
      position: relative;
      margin-top: 2.5em;

      &.has-discount {
        text-align: right;
      }

      p {
        font-size: 1.1em;
        text-align: right;

        &,
        * {
          line-height: 1;
        }

        &.at-price {
          font-size: 0.75em;
          white-space: nowrap;
          position: absolute;
          top: -1.25em;
          right: 0;
        }
      }

      .original-price {
        color: $black;
        position: relative;
        display: inline-block;
        font-size: 1.1em;

        &:after {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          right: 0;
          border-top: 1px solid;
          border-color: inherit;
        }
      }

      .original-price + .price {
        color: $red;
        display: inline-block;
        margin-left: 0.25em;
      }
    }
  }

  > .product:last-of-type {
    border-bottom: 0 !important;
    margin-bottom: 0;
  }
}

.cart-is-empty {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 4em;

  .button {
    margin-top: 1em;
    border: 1px solid $primary-color;
  }
}
</style>
