export default {
  namespaced: true,

  state: {
    storeImages: []
  },

  mutations: {
    setImages (state, sentImages) {
      state.storeImages = sentImages
    }
  },

  actions: {
    getImages ({ commit, state }, url) {
      if (state.storeImages.length === 0) {
        return fetch(url).then(res => res.json()).then(
          response => {
            commit('setImages', response.files)
          }
        )
      } else {
        return state.storeImages
      }
    }
  }
}
