import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/index'

Vue.config.productionTip = false

Vue.component('vue-image', () => import('@/components/VueImage'))
Vue.component('loading', () => import('@/components/Loading'))
Vue.component('quantity-input', () => import('@/components/QuantityInput'))

Vue.filter('currency', n => parseFloat(n).toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD'
}).replace('.00', ''))

Vue.use(VueGtag, {
  config: { id: 'UA-118753296-1' }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
