<template lang="pug">
  .subtotal-checkout(v-if="cartSize > 0" :class="{ 'cart-page-subtotal-checkout' : isCart }")
    .title-subtotal
      h3.summary(v-if="isCart") Order Summary
      .subtotal
        span.subtotal-text Subtotal:
        strong.subtotal-price(v-if="cart.lineItemsSubtotalPrice") &nbsp;{{ cart.lineItemsSubtotalPrice.amount | currency }}
      a.button.hover(:href="cart.webUrl" name='checkout' @click="goingToCheckout = true" :class="{ 'loading': goingToCheckout }") Checkout
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    isCart: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      goingToCheckout: false
    }
  },

  computed: {
    ...mapState('cart', [
      'cart'
    ]),

    ...mapGetters('cart', [
      'cartSize'
    ])
  }
}
</script>

<style lang="scss" scoped>
.subtotal-checkout {
  border-top: 1px solid $gray;

  .title-subtotal {
    padding: 1em;

    .summary {
      margin-top: 0;
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      .empty {
        a {
          text-align: center;
        }
      }

      .subtotal-price {
        font-size: 1.25em;
      }
    }
  }

  .button {
    margin: 1em 0 0;
    width: 100%;
    text-align: center;
  }

  //override ReCharge styles because we have AJAX cart
  //and vue handles the logic for showing/hiding these
  //this is only for when you have a recharge product in cart,
  //remove it, and don't reload the page
  form[action^="/cart"] [type="submit"] {
    visibility: visible;
  }
}

// Specific to cart page
.cart-page-subtotal-checkout {
  border-top: none;

  @media(min-width: $bp-s) {
    padding-left: 1.25em;
  }

  @media(min-width: $bp-m) {
    width: 100%;
    max-width: 425px;
    margin-right: 0;
    margin-left: auto;
  }

  .title-subtotal {
    background: $gray-light;
  }

  button {
    margin: 1em 0 0;
    width: 100%;
  }
}
</style>
