<template lang="pug">
.upsell(v-if='cart.lineItems.length > 0 && upsellProduct.title && !upsellProductInCart')
  .upsell-image
    a(@click="goToProductFromVariant()")
      vue-image.upsell-image(
        :source='computedImage'
        :width='500'
        :height='500'
      )
  .upsell-info
    .upsell-text
      h4.upsell-title.capitalize
        .secondary-font(@click="goToProductFromVariant()")
          | Add
          | #[a(@click="goToProductFromVariant()") {{ upsellProduct.title }}]
          //- span(v-if='selectedVariant.title')&nbsp;({{ selectedVariant.title }})&nbsp;
          | for {{ selectedVariant.price && selectedVariant.price.amount ? selectedVariant.price.amount : selectedVariant.price | currency }}
    .upsell-modify
      select(v-model='selectedVariant' v-if='upsellProduct.variants && upsellProduct.variants.length > 1')
        option(
          v-for='(variant, i) in upsellProduct.variants'
          :key='variant.id'
          :value='variant'
          :disabled="!variant.available"
        ) {{ variant.title }}
      button(@click='addToCart' v-bind:class="{ 'loading': addingToCart }") Add
</template>

<script>
import { mapState } from 'vuex'
import { goToProduct } from '@/helpers/goToProduct'

export default {
  data () {
    return {
      upsellProductInCart: true,
      selectedVariant: false
    }
  },

  computed: {
    ...mapState('products', [
      'upsellProduct'
    ]),

    ...mapState('cart', [
      'cart',
      'addingToCart'
    ]),

    ...mapState('products', [
      'product'
    ]),

    computedImage () {
      if (
        this.selectedVariant &&
        this.selectedVariant.image &&
        !this.selectedVariant.image.src
      ) {
        return this.selectedVariant.image.src
      }

      return this.upsellProduct &&
      this.upsellProduct.images &&
      this.upsellProduct.images.length > 0 &&
      this.upsellProduct.images[0].src
    }
  },

  watch: {
    cart () {
      this.checkUpsellInCart()
    }
  },

  async mounted () {
    await this.$store.dispatch('products/fetchUpsellProduct')

    if (this.upsellProduct && this.upsellProduct.variants.length > 0) {
      this.upsellProduct.variants.forEach((variant) => {
        if (variant.available && !this.selectedVariant) {
          this.selectedVariant = variant
        }
      })
    }

    this.checkUpsellInCart()

    // Shut it down if no variants available
    if (!this.selectedVariant) {
      this.upsellProduct = false
    }
  },

  methods: {
    goToProduct,

    goToProductFromVariant (item) {
      this.goToProduct(this.upsellProduct, true)
    },

    checkUpsellInCart () {
      let inCart = false

      if (this.cart && this.cart.lineItems.length > 0) {
        this.cart.lineItems.forEach((item) => {
          if (
            this.upsellProduct.id === item.id ||
            this.upsellProduct.id === item.variant.id
          ) {
            inCart = true

            return
          }

          if (this.upsellProduct && this.upsellProduct.variants) {
            this.upsellProduct.variants.forEach((variant) => {
              if (
                item.id === variant.id ||
                item.variant.id === variant.id
              ) {
                inCart = true
              }
            })
          }
        })
      }

      this.upsellProductInCart = inCart
    },

    async addToCart () {
      await this.$store.dispatch('cart/addProduct', [{
        variantId: this.selectedVariant.id,
        quantity: 1
      }])
    }
  }
}
</script>

<style lang="scss" scoped>
.upsell {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $gray !important;
  padding: 1em;
  margin-bottom: 1em;
  margin-top: calc(-1em - 1px);
  text-align: left;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .upsell-image {
    flex-basis: 23.5%;

    img {
      max-width: 100%;
    }
  }

  .upsell-info {
    flex-basis: 37%;
    flex-grow: 1;
    padding: 0 1em;

    .upsell-title {
      margin: 0 0 0.5em;
    }
  }

  .upsell-modify {
    display: flex;

    select, button {
      padding: 7px 20px !important;
      font-size: 0.75em !important;
      width: auto;
    }

    select {
      background-position: calc(100% - 15px) calc(1em + -1px), calc(100% - 10px) calc(1em + -1px), calc(100% - 2.5em) 0.5em;
      padding-right: 30px !important;
      margin-right: 1em;
    }
  }
}
</style>
