import setMetaHelper from '@/helpers/meta/setMetaHelper'
import baseMeta from '@/helpers/meta/baseMeta'

export function setMetaForProduct (product) {
  const localbaseMeta = baseMeta()
  const meta = {
    ...localbaseMeta,
    title: `${product.title} | ${localbaseMeta.title}`,
    description: product.description
  }

  setMetaHelper(meta)
}

export function goToProduct (product, fromCart) {
  const path = `/product/${product.handle}`
  if (this.$route.path !== path) {
    setMetaForProduct(product)
    this.$router.push(path)
    this.$store.commit('products/setCurrentProduct', fromCart ? {} : product)
  }

  if (this.$store.state.showSideCart) this.$store.commit('toggleSideCart')
}

export function goToProductFromCart (item) {
  let foundProduct = false

  let productData = { handle: item.variant.product.handle }
  this.products.forEach(product => {
    if (!foundProduct && product.handle === productData.handle) {
      productData = product
      foundProduct = true
    }
  })

  this.goToProduct(productData, !foundProduct)
}
