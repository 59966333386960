<template>
  <div id="app" v-cloak>
    <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/work">Work</router-link>
      <router-link to="/photography">Photography</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/contact">Contact</router-link>
      <router-link to="/store">Store</router-link>
      <router-link to="/misc">Misc</router-link>
      <cart-icon />
      <side-cart :show-side-cart="showSideCart" v-cloak />
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CartIcon from '@/components/CartIcon'
import SideCart from '@/components/SideCart'

export default {
  components: {
    CartIcon,
    SideCart
  },

  computed: {
    ...mapState([
      'showSideCart'
    ])
  },

  async beforeMount () {
    // wait a few seconds to allow fetching all the data
    // before emitting the prerender hook
    setTimeout(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 2000)
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,900");
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.2/css/font-awesome.min.css');
@import '@/styles/_elements.scss';
@import '@/styles/_helpers.scss';
@import "@/styles/_drawer.scss";
@import '@/styles/_hamburger.scss';

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-leave-active {
  display: none;
}

.fade-leave-active {
  transition-duration: 0.15s;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  background: #fdfdfd;
}

#nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0.75em;

  > a, > .cart-icon {
    display: inline-block;
    padding: 0.75em 1em;

    &.router-link-exact-active {
      text-decoration: underline;
    }
  }
}
</style>
